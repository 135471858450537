<template>
  <div>
<!--    <div class="mt-4">-->
<!--      <div class="relative -mt-3" uk-slider="finite: true">-->
<!--        <div class="uk-slider-container px-1 py-3">-->
<!--          <ul class="uk-slider-items uk-child-width-1-5@m uk-child-width-1-3@s uk-child-width-1-2 uk-grid-small uk-grid">-->

<!--            <li>-->
<!--              <div class="rounded-md overflow-hidden relative w-full h-36">-->
<!--                <div class="absolute w-full h-3/4 -bottom-12 bg-gradient-to-b from-transparent to-gray-800 z-10">-->
<!--                </div>-->
<!--                <img src="https://static.bcdcnt.net/assets/images/bcdcnt/nhac-tien-chien.jpg" class="absolute w-full h-full object-cover" alt="">-->
<!--                <div class="absolute bottom-0 w-full p-3 text-white z-20 font-semibold text-lg">Nhạc tiền chiến</div>-->
<!--              </div>-->
<!--            </li>-->
<!--            <li>-->
<!--              <div class="rounded-md overflow-hidden relative w-full h-36">-->
<!--                <div class="absolute w-full h-3/4 -bottom-12 bg-gradient-to-b from-transparent to-gray-800 z-10">-->
<!--                </div>-->
<!--                <img src="assets/images/bcdcnt/nhac-cach-mang.jpg" class="absolute w-full h-full object-cover" alt="">-->
<!--                <div class="absolute bottom-0 w-full p-3 text-white z-20 font-semibold text-lg">Nhạc cách mạng</div>-->
<!--              </div>-->
<!--            </li>-->
<!--            <li>-->
<!--              <div class="rounded-md overflow-hidden relative w-full h-36">-->
<!--                <div class="absolute w-full h-3/4 -bottom-12 bg-gradient-to-b from-transparent to-gray-800 z-10">-->
<!--                </div>-->
<!--                <img src="assets/images/bcdcnt/nhac-nhe.jpg" class="absolute w-full h-full object-cover" alt="">-->
<!--                <div class="absolute bottom-0 w-full p-3 text-white z-20 font-semibold text-lg">Nhạc nhẹ</div>-->
<!--              </div>-->
<!--            </li>-->
<!--            <li>-->
<!--              <div class="rounded-md overflow-hidden relative w-full h-36">-->
<!--                <div class="absolute w-full h-3/4 -bottom-12 bg-gradient-to-b from-transparent to-gray-800 z-10">-->
<!--                </div>-->
<!--                <img src="assets/images/bcdcnt/nhac-thieu-nhi.png" class="absolute w-full h-full object-cover"-->
<!--                     alt="">-->
<!--                <div class="absolute bottom-0 w-full p-3 text-white z-20 font-semibold text-lg">Nhạc thiếu nhi</div>-->
<!--              </div>-->
<!--            </li>-->
<!--            <li>-->
<!--              <div class="rounded-md overflow-hidden relative w-full h-36">-->
<!--                <div class="absolute w-full h-3/4 -bottom-12 bg-gradient-to-b from-transparent to-gray-800 z-10">-->
<!--                </div>-->
<!--                <img src="assets/images/bcdcnt/nhac-nuoc-ngoai.png" class="absolute w-full h-full object-cover"-->
<!--                     alt="">-->
<!--                <div class="absolute bottom-0 w-full p-3 text-white z-20 font-semibold text-lg">Nhạc nước ngoài</div>-->
<!--              </div>-->
<!--            </li>-->
<!--            <li>-->
<!--              <div class="rounded-md overflow-hidden relative w-full h-36">-->
<!--                <div class="absolute w-full h-3/4 -bottom-12 bg-gradient-to-b from-transparent to-gray-800 z-10">-->
<!--                </div>-->
<!--                <img src="assets/images/bcdcnt/nhac-tru-tinh.jpg" class="absolute w-full h-full object-cover" alt="">-->
<!--                <div class="absolute bottom-0 w-full p-3 text-white z-20 font-semibold text-lg">Nhạc trữ tình</div>-->
<!--              </div>-->
<!--            </li>-->

<!--          </ul>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
    <song-list :songs="songs" v-on:loadSongs="loadSongs" :title="title" prefix="bai-hat"/>
  </div>
</template>

<script>
import ApiService from "../../core/services/api.service";
import SongList from "../song/SongList";
import {changePageTitle} from "../../core/services/utils.service";

export default {
  name: 'Music',
  components: {
    SongList
  },
  data() {
    return {
      title: "Tân nhạc",
      songs: {
        data: [],
        paginatorInfo: {
          currentPage: 1,
          lastPage: 0,
          total: 0
        }
      }
    }
  },
  methods: {
    loadSongs(options) {
      let query = `query($page: Int, $orderBy: [OrderByClause!], $vovHQ: Boolean) {
        songs(first: 20, page: $page, orderBy: $orderBy, vovHQ: $vovHQ) {
          data {
            id
            slug
            title
            views
            downloads
            thumbnail {
              url
            }
            file {
              is_hq
            }
            sheet {
              year
              composers(first: 20) {
                data {
                  id
                  slug
                  title
                }
              }
            }
            artists(first: 20) {
              data {
                id
                slug
                title
                avatar {
                  url
                }
              }
            }
          },
          paginatorInfo {
            currentPage
            lastPage
            total
          }
        }
      }`;

      ApiService.graphql(query, options)
          .then(({data}) => {
            if (data.data && data.data.songs) {
              this.songs = data.data.songs;
            }
          })
          .catch((response) => {
            console.log(response);
          });
    }
  },
  mounted() {
    changePageTitle(this.title);
  }
}
</script>
